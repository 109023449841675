.aptitude {
    padding-left: 50px;
    width: 100%;
    height: calc(100vh - 90px);
    overflow: scroll;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
}

.aptitude .confetti {
    position: fixed;
    top: 0;
    width: 50%;
    left: 25%;
    z-index: 100;
}

.aptitude .overlay {
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 400;
}

.aptitude .celebration,
.aptitude .failed-pop-up {
    position: relative;
    width: 30%;
    background-color: white;
    padding: 20px 50px;
    z-index: 500;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.aptitude .pop-up,
.aptitude .failed-pop-container {
    /*height: 200px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aptitude .celebration .pop-up-close,
.aptitude .failed-pop-up .pop-up-close {
    font-size: 20px;
    position: absolute;
    right: 20px;
    cursor: pointer;
    z-index: 5000;
}

.aptitude .pop-up h3 {
    color: #12b76a;
}

.aptitude .pop-up h4 {
    color: #12b76a;
    font-size: 20px;
}

.aptitude .pop-up .loader {
    height: 100px;
}

.aptitude .pop-up button {
    background: #2666be;
    border-radius: 6px;
    padding: 8px 20px 8px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
    /* identical to box height, or 143% */

    /* TEXT / Light Text */

    color: #ffffff;
}

.aptitude .warn h4 {
    color: #555555 !important;
    font-weight: 500;
    margin-bottom: 20px;
}

.aptitude .warn p {
    margin-bottom: 30px;
    font-size: 14px;
    text-align: center;
}

.aptitude .failed-pop-up h3 {
    color: #ef574c;
}

.aptitude .pop-up .congrats,
.aptitude .failed-pop-up .failed-svg {
    height: 100px;
}

.aptitude .column-1 {
    width: 90%;
    height: 100%;
}

.aptitude .heading {
    margin: 30px 0px 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}

.aptitude .meta-info {
    font-weight: 500;
    font-size: 12px;
    color: #ef574c;
    letter-spacing: 1px;
    line-height: 150%;
    background-color: #fde6e4;
    padding: 5px 10px;
    width: 95%;
    margin-bottom: 15px;
}

.aptitude .heading .total-score {
    font-size: 20px;
    background-color: rgba(253, 242, 214, 0.5);
    color: #f0b416;
    border-radius: 5px;
    padding: 5px 10px;
}

.aptitude .column-1 .questionContainer {
    width: 95%;
    border-bottom: 1px solid #e0e0e0;
}

.aptitude .column-1 .question-panel {
    height: 100%;
    margin-top: 20px;
}

@media (max-width: 1000px) {
}

.aptitude .column-1 .questionContainer .question-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.aptitude .conceptual-question .save-btn {
    background: #2666be;
    border-radius: 6px;
    padding: 8px 20px 8px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
    /* identical to box height, or 143% */

    /* TEXT / Light Text */

    color: #ffffff;
}

.aptitude .column-1 .submit-btn {
    margin-top: 10px;
    background: #2666be;
    border-radius: 6px;
    padding: 8px 20px 8px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* TEXT / Light Text */

    color: #ffffff;
}

.aptitude .column-1 .no-questions {
    font-size: 30px;
}

.aptitude .column-1 .no-questions .go-back-btn {
    margin-top: 30px;
    background: #2666be;
    border-radius: 6px;
    padding: 8px 20px 8px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* TEXT / Light Text */

    color: #ffffff;
}

.aptitude .column-1 .questionContainer .question-type {
    padding: 3px 5px;
    width: max-content;
    font-size: 16px;
    /*background-color: rgba(253, 242, 214, 0.5);*/
    color: #f0b416;
    border: 2px solid #f0b416;
    border-radius: 5px;
}

.aptitude .conceptual-question {
    height: 100%;
    width: 100%;
}

.aptitude .conceptual-question .question {
    padding-bottom: 20px;
    /*border-bottom: 1px solid #e0e0e0;*/
}

.aptitude .conceptual-question .options {
    margin: 10px;
}

.aptitude .conceptual-question .options ul li {
    padding: 10px;
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
}

.aptitude .conceptual-question .options ul li .mcq-option {
    width: calc(100% - 60px);
    padding-top: 3px;
}

.aptitude .conceptual-question .options ul li span {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    margin-right: 10px;
}

.aptitude .conceptual-question .options ul li:hover {
    background-color: rgba(253, 242, 214, 0.5);
}

.aptitude .conceptual-question .options ul li.correct {
    background-color: #e3ffed;
}

.aptitude .conceptual-question .options ul li.incorrect {
    background-color: #fde6e4;
}

.aptitude .column-2 ul {
    height: 100%;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    overflow: scroll;
    padding: 10px;
}

.aptitude .column-2 ul::-webkit-scrollbar {
    display: none;
}

.aptitude .column-2 ul li {
    padding: 15px 20px;
    margin-bottom: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    text-transform: capitalize;
    width: 100%;
}

.aptitude .column-2 ul li:hover {
    background-color: #d5e6fb;
    color: #2f80ed;
}

.aptitude .column-2 ul li.active {
    background-color: #d5e6fb;
    color: #2f80ed;
}

.aptitude .answer {
    padding: 10px;
}

.aptitude .integer-type .answer input {
    width: 40%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    border: transparent;
}

.aptitude .subjective .answer textarea {
    min-height: 200px;
    width: 90%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    border: transparent;
}

.aptitude .conceptual-question .correct-answer {
    margin-top: 50px;
    padding: 10px;
}

.aptitude .conceptual-question .correct-answer h1 {
    margin-bottom: 25px;
    font-size: 30px;
}

.aptitude .conceptual-question .correct-answer ul li {
    padding: 10px;
    background-color: rgba(227, 255, 237, 0.5);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 5px;
    margin-bottom: 20px;
}
