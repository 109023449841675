.contest {
    position: relative;
    background-color: var(--white);
    width: 100%;
    height: 100vh;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 800;

        .modal-container {
            width: 500px;
            height: 450px;
            background-color: var(--white);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            z-index: 1000;

            p {
                font-size: 12px;
                width: 80%;
                text-align: center;
            }

            .animation {
                width: 200px;
            }

            button {
                margin-top: 10px;
                padding: 10px 20px;
                background-color: var(--blue);
                border: none;
                color: var(--white);
                border-radius: 4px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
        }
    }

    .proctoring-options {
        //background-color: var(--white);
        position: absolute;
        top: 0;
        right: 0;
        //display: flex !important;
        //flex-direction: row;
        //align-items: center;
        //justify-content: space-evenly;
        color: #ffffff;

        div {
            color: #ffffff !important;
        }

        .notification {
            position: absolute;
            top: 80px;
            right: 20px;
            z-index: 100;
            background-color: var(--red);
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
        }
    }

    .main-screen {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: start;
        position: relative;
        height: calc(100vh - 70px);

        .contest-routes {
            width: 100%;
            height: 100%;

            .question-navigator {
                height: calc(100vh - 90px);
                overflow: scroll;
                width: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: start;
                padding-top: 20px;

                //.all-btn {
                //    width: 50px;
                //    height: 50px;
                //    padding: 10px;
                //    display: flex;
                //    align-items: center;
                //    justify-content: center;
                //    border-radius: 8px;
                //    margin-bottom: 20px;
                //    background-color: var(--light-gray);
                //    cursor: pointer;
                //}

                ul {
                    height: 100%;

                    li {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 8px;
                        margin-bottom: 20px;
                        background-color: var(--light-gray);
                        cursor: pointer;
                    }

                    .active-question {
                        background-color: var(--light-green);
                    }
                }
            }
        }
    }

    .contest-start-popup {
        width: 500px;
        padding: 10px 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin: 50px auto;
        border-radius: 10px;

        .before-start-info {
            width: 90%;
            margin: 10px 5%;

            .instructions-list {
                margin: 20px 0px;
                list-style-type: disc !important;

                li {
                    margin-bottom: 10px;
                    font-size: 14px;
                }
            }
        }

        button {
            width: 90%;
            background-color: var(--blue);
            color: var(--white);
            text-transform: capitalize;
            padding: 5px 10px;
            border-radius: 5px;
            margin: 10px 5%;
        }

        .waiting-prompt {
            margin: 10px 5%;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                font-size: 14px;
                margin: 10px;
                text-align: center;
            }

            .wait-timer {
                .timer {
                    ul {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        li {
                            font-size: 14px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: start;
                            margin: 10px;
                            border: 1px solid #000;
                            border-radius: 5px;
                            padding: 5px 10px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:500px) {
   
    .contest{
        width: 100%;
        margin: auto;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 800;
    
            .modal-container {
                width: 350px;
                height: 200px;
                background-color: var(--white);
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 20px;
                z-index: 1000;
    
                p {
                    font-size: 12px;
                    width: 80%;
                    text-align: center;
                }
    
                .animation {
                    width: 200px;
                }
    
                button {
                    margin-top: 10px;
                    padding: 10px 20px;
                    background-color: var(--blue);
                    border: none;
                    color: var(--white);
                    border-radius: 4px;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                }
            }
        }
        
        .contest-start-popup {
            width: 90%;
            padding: 10px 20px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            margin: 50px auto;
            border-radius: 10px;
    
            .before-start-info {
                width: 100%;
                margin: 10px 5%;

                h2{
                    font-size: 24px;
                }
    
                .instructions-list {
                    margin: 20px 0px;
                    list-style-type: disc !important;
    
                    li {
                        margin-bottom: 10px;
                        font-size: 12px;
                    }
                }
            }
    
            button {
                width: 90%;
                background-color: var(--blue);
                color: var(--white);
                text-transform: capitalize;
                padding: 5px 10px;
                border-radius: 5px;
                margin: 10px 5%;
            }
    
            .waiting-prompt {
                margin: 10px 5%;
                width: 90%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
    
                p {
                    font-size: 14px;
                    margin: 10px;
                    text-align: center;
                }
    
                .wait-timer {
                    .timer {
                        ul {
                            display: flex;
                            align-items: center;
                            justify-content: center;
    
                            li {
                                font-size: 14px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: start;
                                margin: 10px;
                                border: 1px solid #000;
                                border-radius: 5px;
                                padding: 5px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    
}
