.buffer {
    margin-top: 50px;
    display: flex;
    align-items: start;
    flex-direction: row;
    justify-content: start;
    width: 80%;
    margin-left: 10%;
    height: calc(100vh - 70px);

    .instructions {
        width: 75%;

        .contest-name {
            margin-bottom: 20px;
            width: 90%;
            border-bottom: 1px solid var(--gray);
            padding-bottom: 10px;
        }

        h3 {
            font-size: var(--medium-heading);
        }

        .instructions-markdown {
            width: 90%;
            height: calc(100vh - 400px);
            overflow: scroll;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            padding: 10px;
            margin-top: 20px;
        }
    }

    .wait {
        width: 25%;
        background-color: var(--light-blue);
        padding: 15px;
        border-radius: 5px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        h1 {
            font-size: var(--small-heading);
            font-weight: var(--regular);
            letter-spacing: 1px;
            margin-bottom: 10px;
        }

        .timer {
            width: 100%;

            #countdown {
                ul {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    li {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin: 10px;
                        color: var(--blue);
                        border: 1px solid var(--blue);
                        width: 50px;
                        height: 50px;
                        border-radius: 5px;

                        span {
                            color: var(--blue);
                        }
                    }
                }
            }
        }

        ol {
            padding: 15px;
        }

        .start-test-btn {
            border-radius: 5px;
            background-color: var(--blue);
            padding: 10px;
            color: var(--white);
            width: 100%;
            cursor: pointer;
            border: none;

            &:disabled {
                background-color: var(--dark-gray);
            }
        }
    }
}

@media screen and (max-width:500px) {
    .buffer {
        margin-top: 0px;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: start;
        width: 100%;
        margin-left: 0;
        height: calc(100% - 70px);
        overflow-y: scroll;
        padding: 0 15px;
    
        .instructions {
            width: 100%;
    
            .contest-name {
                margin-bottom: 10px;
                width: 100%;
                border-bottom: 1px solid var(--gray);
                padding-bottom: 10px;
                text-transform: capitalize;
                margin-top: 10px;
                font-size: 30px;

            }
    
            h3 {
                font-size: 24px;
                
            }
    
            .instructions-markdown {
                width: 100%;
                height: max-content;
                overflow: scroll;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                padding: 10px;
                margin-top: 10px;
                margin-bottom: 20px;
                border-radius: 8px;

                ol{
                    padding:10px;
                    padding-left: 10px;
                    list-style: disc;
                }
            }
        }
    
        .wait {
            width: 100%;
            background-color: var(--light-blue);
            padding: 15px;
            border-radius: 5px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
            h1 {
                font-size: 18px;
                font-weight: var(--regular);
                letter-spacing: 1px;
                margin-bottom: 10px;
            }
    
            .timer {
                width: 100%;
    
                #countdown {
                    ul {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
    
                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            margin: 10px;
                            font-size: 12px;
                            font-weight: 700;
                            color: var(--blue);
                            border: 1px solid var(--blue);
                            width: 40px;
                            height: 40px;
                            border-radius: 5px;
    
                            span {
                                color: var(--blue);
                            }
                        }
                    }
                }
            }
    
            ol {
                padding: 15px;
                font-size: 14px;
            }
    
            .start-test-btn {
                border-radius: 5px;
                background-color: var(--blue);
                padding: 8px;
                color: var(--white);
                width: 100%;
                font-size: 16px;
                cursor: pointer;
                border: none;
    
                &:disabled {
                    background-color: var(--dark-gray);
                }
            }
        }
    }

}
